<template>
  <a @click="openImageModle">
    <i class="icon-file file-select"></i>
    <span class="operate-txt">从素材库选择</span>
  </a>
  <a-modal v-model:visible="image_visible" width="1000px" style="max-width: none" @ok="handleSubmit" @cancel="handleCancel" title="图片库">
    <div style="height: 550px">
      <a-row>
        <a-col :span="5">
          <div class="image-group">
            <a-tree v-if="treeData" class="draggable-tree" draggable block-node default-expand-all :tree-data="treeData" :fieldNames="replaceFields" @select="selectGroup" />
          </div>
        </a-col>
        <a-col :span="19">
          <div class="pop-list">
            <div class="action-top">
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="16">
                  <div class="upload-action">
                    <span class="upload-desc">大小不能超过2M</span>
                    <a-upload name="iFile" :headers="headers" :multiple="true" :showUploadList="false" :action="uploadAction" @change="handleChange">
                      <a-button>
                        <CloudUploadOutlined />
                        上传
                      </a-button>
                    </a-upload>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="pop-list-body">
              <div v-if="imageData.image_list.length > 0">
                <a-spin :spinning="spinning">
                  <div class="image-list-box">
                    <a-row type="flex">
                      <a-col :span="4" v-for="(item, index) in imageData.image_list" :key="index">
                        <div :class="selectedItem == item ? 'image-item active':'image-item'" @click="selectImage(item)">
                          <div class="image-cover-box">
                            <a-image class="image-cover" :preview="false" :src="item.value.url" />
                          </div>
                          <p class="image-file-name">{{ item.name }}</p>

                          <div class="active-mask">
                            <CheckOutlined class="active-icon" />
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                  <div class="pop-footer">
                    <div class="pop-pagination">
                      <a-pagination v-model:current="imageData.current_page" v-model:pageSize="imageData.per_page" :total="imageData.total" show-less-items @change="onLoadImageList" />
                    </div>
                  </div>
                </a-spin>
              </div>
              <div v-else>
                <a-empty />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { message } from "ant-design-vue";
import { CloudUploadOutlined, CheckOutlined } from "@ant-design/icons-vue";
import * as Api from "@/views/client/wechatApi";
import "./style/common.less";
import "./style/image.less";
import storage from "store";

export default defineComponent({
  components: {
    CloudUploadOutlined,
    CheckOutlined,
  },
  name: "CsImage",
  props: {
    image: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    // 音频
    const image = computed({
      get: () => props.image,
      set: (value) => emit("update:image", value),
    });

    // 上传弹框
    const image_visible = ref(false);

    const openImageModle = () => {
      getGroupList();
      onLoadImageList();
      image_visible.value = true;
    };

    // 已经被选中的索引
    const selectedItem = ref([]);

    const selectImage = (item) => {
      selectedItem.value = item;
    };

    // 确定选择触发
    const handleSubmit = () => {
      if (selectedItem.value.length == 0) {
        message.error("请选择音频文件");
      } else {
        image.value = selectedItem.value.media_id;
        image_visible.value = false;
      }
    };

    const handleCancel = () => {};

    const uploadImage = () => {};

    //上传文件
    const uploadAction = Api.wechat_upload_image;
    const headers = ref({
      "Access-Token": storage.get("ACCESS_TOKEN"),
    });

    const handleChange = (info) => {
      if (info.file.status === "done") {
        if (info.file.response.status === 200) {
          onLoadImageList();
          message.success(`${info.file.name} 上传成功`);
        } else {
          message.error(info.file.response.message);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    // 打开图片库获取分组
    const treeData = ref();

    //获取分组数据

    const getGroupList = () => {
      // 获取图片库分组数据
      // Api.group_list({ type: 30 }).then((res) => {
      //   treeData.value = res.data.list;
      // });
    };

    const selectGroup = (selectKeys) => {
      if (selectKeys.length > 0) {
        imageData.group_id = parseInt(selectKeys[0]);
      } else {
        imageData.group_id = -1;
      }
      onLoadImageList();
    };

    // 请求相册列表
    const imageData = reactive({
      keyword: "",
      image_list: [],
      total: 0,
      current_page: 0,
      per_page: 10,
      group_id: -1,
    });

    const spinning = ref(true); //加载效果

    const onLoadImageList = (page = 1) => {
      spinning.value = true;
      console.log(imageData.per_page);
      Api.wechat_list_material({
        page: page,
        group_id: imageData.group_id,
        keyword: imageData.keyword,
        type: 3,
        limit: imageData.per_page,
      }).then((res) => {
        imageData.image_list = res.data.material_list.data;
        imageData.total = res.data.material_list.total;
        imageData.current_page = res.data.material_list.current_page;
        imageData.per_page = res.data.material_list.per_page;

        spinning.value = false;
      });
    };

    const replaceFields = {
      children: "children",
      title: "name",
      key: "group_id",
      parent_id: "parent_id",
    };

    return {
      headers,
      uploadImage,
      image_visible,
      openImageModle,
      handleSubmit,
      handleCancel,
      treeData,
      imageData,
      selectGroup,
      spinning,
      onLoadImageList,
      selectImage,
      selectedItem,
      uploadAction,
      handleChange,
      replaceFields,
    };
  },
});
</script>