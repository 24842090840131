<template>
  <a @click="openMaterialModle">
    <i class="icon-file file-select"></i>
    <span class="operate-txt">从素材库选择</span>
  </a>
  <a-modal v-model:visible="material_visible" width="750px" style="max-width: none" @ok="handleSubmit" @cancel="handleCancel" title="图文库">
    <div>
      <div class="pop-list">
        <div class="action-top">
          <a-row>
            <a-col :span="16">
            </a-col>
            <a-col :span="8">
              <div class="search-box">
                <a-input-search placeholder="搜索文件名称" v-model:value="materialData.keyword" @search="onSearch" />
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="pop-list-body">
          <div v-if="materialData.material_list.length > 0">
            <a-spin :spinning="spinning">
              <div class="material-list-box">
                <a-row type="flex">
                  <a-col :span="12" v-for="(item, index) in materialData.material_list" :key="index">
                    <div :class="item == selectedItem ?'material-item active' :'material-item'" @click="selectMaterial(item)">
                      <a-image class="material-cover-image" :src="item.value.thumb_url" />
                      <h3 class="material-title">{{ item.name }}</h3>
                      <p class="material-desc">{{ item.value.digest }}</p>
                      <div class="material-time">{{ item.create_time }}</div>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="pop-footer">
                <div class="pop-pagination">
                  <a-pagination v-model:current="materialData.current_page" v-model:pageSize="materialData.per_page" :total="materialData.total" show-less-items @change="onLoadMaterialList" />
                </div>
              </div>
            </a-spin>
          </div>
          <div v-else>
            <a-empty />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/views/client/wechatApi";
import "./style/common.less";
import "./style/material.less";

export default defineComponent({
  components: {},
  name: "CsMaterial",
  props: {
    material: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    // 音频
    const material = computed({
      get: () => props.material,
      set: (value) => emit("update:material", value),
    });

    // 上传弹框
    const material_visible = ref(false);

    const openMaterialModle = () => {
      getGroupList();
      onLoadMaterialList();
      material_visible.value = true;
    };

    // 已经被选中的索引
    const selectedItem = ref([]);

    const selectMaterial = (item) => {
      selectedItem.value = item;
    };

    // 确定选择触发
    const handleSubmit = () => {
      if (selectedItem.value.length == 0) {
        message.error("请选择音频文件");
      } else {
        material.value = selectedItem.value.media_id;
        material_visible.value = false;
      }
    };

    const handleCancel = () => {};

    const uploadMaterial = () => {};

    //上传文件
    const uploadAction = Api.wechat_upload;

    const handleChange = (info) => {
      if (info.file.status === "done") {
        if (info.file.response.status === 200) {
          onLoadMaterialList();
          message.success(`${info.file.name} 上传成功`);
        } else {
          message.error(info.file.response.message);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    // 打开图片库获取分组
    const treeData = ref();

    //获取分组数据

    const getGroupList = () => {
      // 获取图片库分组数据
      // Api.group_list({ type: 30 }).then((res) => {
      //   treeData.value = res.data.list;
      // });
    };

    const selectGroup = (selectKeys) => {
      if (selectKeys.length > 0) {
        materialData.group_id = parseInt(selectKeys[0]);
      } else {
        materialData.group_id = -1;
      }
      onLoadMaterialList();
    };

    // 请求相册列表
    const materialData = reactive({
      keyword: "",
      material_list: [],
      total: 0,
      current_page: 0,
      per_page: 10,
      group_id: -1,
    });

    const spinning = ref(true); //加载效果

    const onLoadMaterialList = (page = 1) => {
      spinning.value = true;
      Api.wechat_list_material({
        page: page,
        group_id: materialData.group_id,
        keyword: materialData.keyword,
        type: 1,
        limit: materialData.per_page,
      }).then((res) => {
        materialData.material_list = res.data.material_list.data;
        materialData.total = res.data.material_list.total;
        materialData.current_page = res.data.material_list.current_page;
        materialData.per_page = res.data.material_list.per_page;

        spinning.value = false;
      });
    };

    // 搜索
    const onSearch = () => {
      onLoadMaterialList();
    };

    const replaceFields = {
      children: "children",
      title: "name",
      key: "group_id",
      parent_id: "parent_id",
    };

    return {
      uploadMaterial,
      material_visible,
      openMaterialModle,
      handleSubmit,
      handleCancel,
      treeData,
      materialData,
      selectGroup,
      onSearch,
      spinning,
      onLoadMaterialList,
      selectMaterial,
      selectedItem,
      uploadAction,
      handleChange,
      replaceFields,
    };
  },
});
</script>