<template>
  <div class="main-body">
    <div class="menu-body">
      <div class="menu-preview">
        <div class="phone-preview">
          <div class="phone-bottom">
            <ul class="phone-keyboard">
              <a-row>
                <a-col :span="mainSpan" v-for="(item, index) in dataForm.menu" :key="index">
                  <li class="menu-item">
                    <a @click.stop="updateSelectStatus(index)" :class="
                        dataForm.selectedParent == -1 &&
                        dataForm.selectedIndex == index
                          ? 'menu-title active'
                          : 'menu-title'
                      ">{{ item.title }}</a>
                    <div v-if="
                        dataForm.selectedParent == index ||
                        (dataForm.selectedIndex == index &&
                          dataForm.selectedParent == -1)
                      " class="menu-second-div">
                      <ul class="menu-second">
                        <li :class="
                            dataForm.selectedParent == index &&
                            dataForm.selectedIndex == indexs
                              ? 'menu-second-item actives'
                              : 'menu-second-item'
                          " v-for="(items, indexs) in item.children" :key="indexs">
                          <a class="menu-second-title" @click.stop="updateSelectStatus(indexs, index)">{{ items.title }}</a>
                        </li>
                        <li class="menu-second-item" v-if="item.children.length < 5">
                          <a @click.stop="addSecondNav(index)" class="menu-second-title">
                            <PlusOutlined />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <CaretDownOutlined class="down-icon" />
                  </li>
                </a-col>
                <a-col :span="mainSpan" v-if="dataForm.menu.length < 3">
                  <li class="menu-item">
                    <a href="javascript:void(0);" class="menu-title" @click.stop="addMainNav()">
                      <PlusOutlined />
                    </a>
                  </li>
                </a-col>
              </a-row>
            </ul>
          </div>
        </div>
      </div>
      <div class="menu-form" v-if="dataForm.menu.length > 0">
        <CsMenu :data="itemForm" :isMainMenu="isMainMenu" @delCurMenu="deleteMenu" />
      </div>
    </div>
    <div class="submit-bar" v-if="dataForm.menu.length > 0">
      <a-button type="primary" html-type="submit" @click="onSubmit" class="submit-save-btn">保存并发布</a-button>
      <a-button class="submit-preview">预览</a-button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, computed, onMounted } from "vue";
import "./style/menu.less";
import { CaretDownOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import * as Api from "@/views/client/api";
import { CsMenu } from "@/components/Wechat";
export default defineComponent({
  components: {
    CaretDownOutlined,
    PlusOutlined,
    CsMenu,
  },
  setup() {
    let mainSpan = computed({
      get: () => {
        if (dataForm.menu.length == 0) {
          return 24;
        } else if (dataForm.menu.length == 1) {
          return 12;
        } else if (dataForm.menu.length == 2) {
          return 8;
        } else {
          return 8;
        }
      },
    });

    const defalutVal = {
      title: "菜单名称",
      body_type: "message",
      content_type: "image_text",
      content: "",
      children: [],
    };

    const dataForm = reactive({
      menu: [],
      selectedParent: -1,
      selectedIndex: 0,
    });

    const isMainMenu = computed(() => {
      return dataForm.selectedParent == -1 &&
        dataForm.menu[dataForm.selectedIndex].children.length > 0
        ? true
        : false;
    });

    const itemForm = ref();

    // 添加一级菜单
    const addMainNav = () => {
      if (dataForm.menu.length < 3) {
        dataForm.menu.push(JSON.parse(JSON.stringify(defalutVal)));
        itemForm.value = defalutVal;
        updateSelectStatus(dataForm.menu.length - 1);
      }
    };

    // 添加二级菜单
    const addSecondNav = (index) => {
      if (dataForm.menu[index].children.length < 5) {
        dataForm.menu[index].children.push(
          JSON.parse(JSON.stringify(defalutVal))
        );
        updateSelectStatus(dataForm.menu[index].children.length - 1, index);
      }
    };

    // 更新选中状态
    const updateSelectStatus = (index, parent_id = -1) => {
      dataForm.selectedIndex = index;
      dataForm.selectedParent = parent_id;

      if (parent_id == -1) {
        itemForm.value = dataForm.menu[index];
      } else {
        itemForm.value = dataForm.menu[parent_id].children[index];
      }
    };

    const onSubmit = () => {
      console.log("submit");
      console.log(dataForm);
      Api.wechat_save_menu(dataForm).then((res) => {
        if (res.status == 200) {
          message.success(res.message);
        }
      });
    };

    const getInitMenu = () => {
      Api.wechat_get_menu(dataForm).then((res) => {
        console.log("wechat_get_menu");
        console.log(res.data);
        dataForm.menu = res.data.value;
        itemForm.value = res.data.value[0];
      });
    };

    const deleteMenu = () => {
      // 删除的方法
      console.log("deleteMenu");

      if (dataForm.selectedParent > 0) {
        console.log("二级菜单");
        console.log(
          dataForm.menu[dataForm.selectedParent]["children"][
            dataForm.selectedIndex
          ]
        );
        // this.$delete(dataForm.menu[dataForm.selectedParent]['children'],dataForm.selectedIndex)
        // delete dataForm.menu[dataForm.selectedParent]['children'][dataForm.selectedIndex]
        dataForm.menu[dataForm.selectedParent]["children"].splice(
          dataForm.selectedIndex,
          1
        );

        updateSelectStatus(dataForm.selectedParent);
      } else {
        console.log("一级菜单");
        console.log(dataForm.menu[dataForm.selectedIndex]);
        // this.$delete(dataForm.menu,dataForm.selectedIndex)
        // delete dataForm.menu[dataForm.selectedIndex]
        dataForm.menu.splice(dataForm.selectedIndex, 1);

        updateSelectStatus(0);
      }
    };

    onMounted(() => {
      getInitMenu();
    });

    return {
      dataForm,
      itemForm,
      isMainMenu,
      deleteMenu,
      addMainNav,
      addSecondNav,
      mainSpan,
      updateSelectStatus,
      onSubmit,
    };
  },
});
</script>