import request from '@/utils/http'

/**
 * 应用列表
 * @param payload
 */
export function list(payload) {
    return request({ url: '/Client/index', method: 'get', data: payload })
}

/**
 * 创建应用
 * @param payload
 */
export function create(payload) {
    return request({ url: '/Client/add', method: 'post', data: payload })
}

/**
 * 保存应用配置信息
 * @param payload
 */
export function client_save_config(payload) {
    return request({ url: '/Client/save', method: 'post', data: payload })
}

/**
 * 获取应用配置信息
 * @param payload
 */
export function client_get_config(payload) {
    return request({ url: '/Client/config', method: 'post', data: payload })
}

/**
 * 微信公众号菜单配置设置
 * @param payload
 */
export function wechat_save_menu(payload) {
    return request({ url: '/client/Wechat/menu_save', method: 'post', data: payload })
}

/**
 * 微信公众号菜单配置获取
 * @param payload
 */
export function wechat_get_menu(payload) {
    return request({ url: '/client/Wechat/menu', method: 'get', data: payload })
}

export function wxapp_get_config(payload) {
    return request({ url: '/client/Wxapp/getConfig', method: 'post', data: payload })
}

export function wxapp_save_config(payload) {
    return request({ url: '/client/Wxapp/setConfig', method: 'post', data: payload })
}

/**
 * 获取模板列表
 * @param payload
 */
export function get_template_list(payload) {
    return request({ url: '/client/Template/lists', method: 'post', data: payload })
}

/**
 * 创建-编辑模板信息
 * @param payload
 */
export function get_template_update(payload) {
    return request({ url: '/client/Template/update', method: 'post', data: payload })
}