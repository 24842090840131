<template>
  <a @click="openAudioModle">
    <i class="icon-file file-select"></i>
    <span class="operate-txt">从素材库选择</span>
  </a>
  <a-modal v-model:visible="audio_visible" width="740px" style="max-width: none" @ok="handleSubmit" @cancel="handleCancel" title="音频库">
    <div style="height: 550px">
      <div class="pop-list">
        <div class="action-top">
          <div class="upload-action">
            <span class="upload-desc">由于版本兼容的原因,你暂时只可以选择60秒内的音频发送</span>
            <a-upload name="iFile" :headers="headers" :multiple="true" :showUploadList="false" :action="uploadAction" @change="handleChange">
              <a-button>
                <CloudUploadOutlined />
                上传
              </a-button>
            </a-upload>
          </div>
        </div>
        <div class="pop-list-body">
          <div v-if="audioData.audio_list.length > 0">
            <a-spin :spinning="spinning">
              <div class="audio-list-box">
                <a-row type="flex">
                  <a-col :span="12" v-for="(item, index) in audioData.audio_list" :key="index">
                    <div :class="item == selectedItem ?'audio-item active' :'audio-item'" @click="selectAudio(item)">
                      <a-row justify="space-between">
                        <a-col :span="16">
                          <p class="audio-title">{{ item.name }}</p>
                          <div>{{ item.create_time }}</div>
                        </a-col>
                        <a-col :span="8">
                          <div class="audio-image">
                            <div><i class="icon-audio"></i></div>
                            <div class="audio-time">04:10</div>
                          </div>
                        </a-col>
                      </a-row>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="pop-footer">
                <div class="pop-pagination">
                  <a-pagination v-model:current="audioData.current_page" v-model:pageSize="audioData.per_page" :total="audioData.total" show-less-items @change="onLoadAudioList" />
                </div>
              </div>
            </a-spin>
          </div>
          <div v-else>
            <a-empty />
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { message } from "ant-design-vue";
import { CloudUploadOutlined } from "@ant-design/icons-vue";
import * as Api from "@/views/client/wechatApi";
import "./style/common.less";
import "./style/audio.less";
import storage from "store";

export default defineComponent({
  components: {
    CloudUploadOutlined,
  },
  name: "CsAudio",
  props: {
    audio: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    // 音频
    const audio = computed({
      get: () => props.audio,
      set: (value) => emit("update:audio", value),
    });

    // 上传弹框
    const audio_visible = ref(false);

    const openAudioModle = () => {
      getGroupList();
      onLoadAudioList();
      audio_visible.value = true;
    };

    // 已经被选中的索引
    const selectedItem = ref([]);

    const selectAudio = (item) => {
      selectedItem.value = item;
    };

    // 确定选择触发
    const handleSubmit = () => {
      if (selectedItem.value.length == 0) {
        message.error("请选择音频文件");
      } else {
        audio.value = selectedItem.value.media_id;
        audio_visible.value = false;
      }
    };

    const handleCancel = () => {};

    const uploadAudio = () => {};

    //上传文件
    const uploadAction = Api.wechat_upload_audio;

    const headers = ref({
      "Access-Token": storage.get("ACCESS_TOKEN"),
    });

    const handleChange = (info) => {
      if (info.file.status === "done") {
        if (info.file.response.status === 200) {
          onLoadAudioList();
          message.success(`${info.file.name} 上传成功`);
        } else {
          message.error(info.file.response.message);
        }
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 上传失败.`);
      }
    };

    // 打开图片库获取分组
    const treeData = ref();

    //获取分组数据

    const getGroupList = () => {
      // 获取图片库分组数据
      // Api.group_list({ type: 30 }).then((res) => {
      //   treeData.value = res.data.list;
      // });
    };

    const selectGroup = (selectKeys) => {
      if (selectKeys.length > 0) {
        audioData.group_id = parseInt(selectKeys[0]);
      } else {
        audioData.group_id = -1;
      }
      onLoadAudioList();
    };

    // 请求相册列表
    const audioData = reactive({
      keyword: "",
      audio_list: [],
      total: 0,
      current_page: 0,
      per_page: 10,
      group_id: -1,
    });

    const spinning = ref(true); //加载效果

    const onLoadAudioList = (page = 1) => {
      spinning.value = true;
      Api.wechat_list_material({
        page: page,
        group_id: audioData.group_id,
        keyword: audioData.keyword,
        type: 4,
        limit: audioData.per_page,
      }).then((res) => {
        audioData.audio_list = res.data.material_list.data;
        audioData.total = res.data.material_list.total;
        audioData.current_page = res.data.material_list.current_page;
        audioData.per_page = res.data.material_list.per_page;

        spinning.value = false;
      });
    };

    // 搜索
    const onSearch = () => {
      onLoadAudioList();
    };

    const replaceFields = {
      children: "children",
      title: "name",
      key: "group_id",
      parent_id: "parent_id",
    };

    return {
      headers,
      uploadAudio,
      audio_visible,
      openAudioModle,
      handleSubmit,
      handleCancel,
      treeData,
      audioData,
      selectGroup,
      onSearch,
      spinning,
      onLoadAudioList,
      selectAudio,
      selectedItem,
      uploadAction,
      handleChange,
      replaceFields,
    };
  },
});
</script>